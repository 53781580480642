import React, { useEffect, useState, useContext } from 'react';
import ServiceConfigurationDataTable from '../../../components/ServiceConfigurationDataTable'
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { loadCscCodeData, loadAggregateData, loadAggregateDetailData, sortCSCDataList, deleteOverideCscCodeData, addCscCode5, loadOverrideData, getAllServiceActivationDetails  } from '../../../redux/store/serviceConfiguration';
import { UserContext } from '../../../appContext';
import ServiceConfigurationAggregate from '../../../components/ServiceConfigurationAggregate';
import ServiceConfigurationVehicleAlarmOverride from '../../../components/ServiceConfigurationVehicleAlarmOverride';
import _ from 'lodash';
import './ServiceConfiguration.scss';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import { RBACWrapper } from 'react-simple-rbac';

type ServiceConfigurationProps = {
}

// eslint-disable-next-line no-empty-pattern
const ServiceConfiguration = ({ }: ServiceConfigurationProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { selectedVehicle } = useAppSelector(state => state.universalSearch);
  const { cscData, equipmentAggregate, equipmentaggregatedetail, csc5OverrideValue, isCsc5Overriden, csc5Value, isOverideApiLoading, overrideCscData, searchEquipmentReference, machineryResultList } = useAppSelector<CscCodeStateType>(state => state.cscEquipmentStore);
  const [sortedSearchResults, setSortedSearchResults] = useState<Array<CscCodeResponseType>>([]);
  const [showConfirmMsg, setShowConfrimMsg] = useState<boolean>(false);
  const { settings } = useContext(UserContext);

  useEffect(() => {
    if (cscData && cscData.length > 0) {
      setSortedSearchResults(cscData);
      dispatch(loadOverrideData(selectedVehicle.externalEquipmentReference));
    }
  }, [cscData]);

  useEffect(() => {
    onRefresh();// language change doesnt reflect in the table if not refreshed
  }, []); 

  useEffect(() => {
    if (selectedVehicle && searchEquipmentReference !== selectedVehicle.externalEquipmentReference) {
      dispatch(loadCscCodeData(selectedVehicle.externalEquipmentReference, settings?.language || 'en-GB'));
      dispatch(loadAggregateData(selectedVehicle.externalEquipmentReference));
      dispatch(getAllServiceActivationDetails (selectedVehicle.externalEquipmentReference, settings?.language || 'en-GB'));
      dispatch(loadAggregateDetailData(selectedVehicle.externalEquipmentReference, settings?.language || 'en-GB'));
    }
  }, [selectedVehicle, dispatch])

  const onSortData = (columnId: string, sortDirection: string) => {
    if (cscData && cscData.length > 0) {
      let sortedResults = _.sortBy(cscData, columnId);
      if (sortDirection === 'desc') {
        sortedResults = _.reverse(sortedResults);
      }
      dispatch(sortCSCDataList(sortedResults as Array<CscCodeResponseType>));
    }
  }
  const onSearch = (searchString: string) => {
    if (searchString.length > 0) {
      if (cscData && cscData.length > 0) {
        const splitedString = searchString.split(",");
        let filteredDataArray;
        if (splitedString && splitedString.length > 0) {
          filteredDataArray = splitedString.map((searchstr: string) => {
            searchstr = searchstr.trim();
            if (searchstr === "") return [];
            return cscData.filter(a => _.values(a).some((b) => {
              if (typeof b === 'string') {
                return b.toLocaleLowerCase().includes(searchstr.toLocaleLowerCase())
              } else {
                return b.toString().toLocaleLowerCase() === searchstr.toLocaleLowerCase()
              }
            }
            ));
          })
        }
        setSortedSearchResults(_.flatten(filteredDataArray) as Array<CscCodeResponseType>);
        return false;
      }
    } else {
      setSortedSearchResults(cscData);
    }
  }
  const onAddCscCode5 = (selectedCscValue: number) => {
    dispatch(addCscCode5(selectedVehicle.externalEquipmentReference, 5, selectedCscValue, settings?.language || 'en-GB'));
  }
  const onDeleteCscCode5 = () => {
    dispatch(deleteOverideCscCodeData(selectedVehicle.externalEquipmentReference, 5, settings?.language || 'en-GB'));
    setTimeout(() => {
      setShowConfrimMsg(false);
    }, 100);
  }

  const onRefresh = () => {
    dispatch(loadCscCodeData(selectedVehicle.externalEquipmentReference, settings?.language || 'en-GB'));
  }

  const handleRefreshAggregateDetails = () => {
    dispatch(getAllServiceActivationDetails (selectedVehicle.externalEquipmentReference, settings?.language || 'en-GB'));
    dispatch(loadAggregateDetailData(selectedVehicle.externalEquipmentReference, settings?.language || 'en-GB'));
    dispatch(loadAggregateData(selectedVehicle.externalEquipmentReference));
  }

  return <>
    <div className="panel active" >
      <div className='service-configuration-page'>
        <div className='service-configuration-container sdds-container-fluid'>
          <div className="ta-row sdds-row">
            <div className='dt-col sdds-no-padding sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12'>
              <div className='dt-container aggregate-container sdds-u-mt2'>
                <ServiceConfigurationAggregate data={equipmentAggregate} aggregateDetails={equipmentaggregatedetail} refreshAggregateDetails={handleRefreshAggregateDetails} machineryResultList={machineryResultList} />
              </div>
            </div>
          </div>
          <RBACWrapper oneOf requiredRoles={["Admin", "GTS"]}><>
            {overrideCscData && overrideCscData.length > 0 && (isCsc5Overriden || csc5Value === undefined) && <ServiceConfigurationVehicleAlarmOverride
              externalEquipmentReference={selectedVehicle.externalEquipmentReference}
              isAvailable={csc5OverrideValue !== undefined}
              csc5InitialValue={csc5OverrideValue}
              isOverrideApiLoading={isOverideApiLoading}
              onAddCscCode5={onAddCscCode5}
              onDeleteCscCode5={() => { setShowConfrimMsg(true) }}
            />}
            {showConfirmMsg &&
              <ConfirmationDialog
                isDelete
                onClose={() => { setShowConfrimMsg(false) }}
                onAction={onDeleteCscCode5}
                title={t("TS_core:ConfirmRemoveService")}
                confirmationMsg={t("TS_core:RemoveServiceConfirmMsg")}
                primaryActionBtn={t("TS_core:RemoveService")}
                secondaryActionBtn={t("TS_core:Cancel")} />
            }
          </></RBACWrapper>
          <div className="dt-row sdds-row">
            <div className='dt-col sdds-no-padding sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 '>
              <div className='dt-container'>
                <ServiceConfigurationDataTable Data={sortedSearchResults} onSortData={onSortData} onSearch={onSearch} selectedVehicle={selectedVehicle} onRefresh={onRefresh} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
};

export default ServiceConfiguration;
