import { createSlice } from '@reduxjs/toolkit'
import { sendBatchSearchRequest } from '../../adapters/BatchSearchAdapter';
import { filterResultsForNotfound, transformData } from "../../helpers/batchSearchService";
import _ from 'lodash';


const getInitialState = (): BatchSearchStateType => {
  const batchSearchInitialState: BatchSearchStateType = {
    isLoading: false,
    hasError: false,
    errorMessage: "",
    searchResults: [],
    notFound: [],
    percentage: 0,
  };
  return batchSearchInitialState
}
// Slice
const slice = createSlice({
  name: 'batchSearchState',
  initialState: getInitialState(),
  reducers: {
    updateIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    updateError: (state, action) => {
      state.hasError = action.payload !== '';
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    updateSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },
    updateNotFound: (state, action) => {
      state.notFound = action.payload;
    },
    updateBatchSearchPercentage: (state, action) => {
      state.percentage = action.payload
    },
  },
});
export default slice.reducer

// Actions
const { updateIsLoading, updateError, updateSearchResults, updateNotFound, updateBatchSearchPercentage } = slice.actions

export const searchForEquipmentsInBatch = (searchParams: string[]) => async (dispatch: any, getState: any) => {
  try {
    dispatch(clearBatchSearchData());
    dispatch(updateIsLoading(true));
    const chunckBatchList = _.chunk(searchParams, 20);
    let totalBatches = chunckBatchList.length;
    let completedBatches = 0;
    let {batchSearchStore} = getState();
    let appendingsearchResultsList = [...batchSearchStore.searchResults];
    let appendingnotfoundResultsList = [...batchSearchStore.notFound];
    let batchList = chunckBatchList.map((chunk:any)=>{
      return new Promise(async(resolve:any,reject)=>{
        await sendBatchSearchRequest(chunk)
         .then((res)=>{
          completedBatches++;
          const percentage = Math.ceil((completedBatches / totalBatches) * 100);
          let notfound = chunk;
          dispatch(updateBatchSearchPercentage(percentage));
          let transformedData: SimplifiedBatchSearchResultType[] = [];
          if (res.data && res.data.equipmentDetails && res.data.equipmentDetails.length > 0) {
            transformedData = transformData(res.data && res.data.equipmentDetails);
            if (transformedData.length > 0) {
              notfound = filterResultsForNotfound(chunk, transformedData);
            }
          }
          appendingnotfoundResultsList = appendingnotfoundResultsList.concat(notfound)
          appendingsearchResultsList = appendingsearchResultsList.concat(transformedData);
          dispatch(updateSearchResults(appendingsearchResultsList));
          dispatch(updateNotFound(appendingnotfoundResultsList));
            resolve();
         }).catch(reject);
      })
    });
    
    await Promise.all(batchList);
    dispatch(updateIsLoading(false));
  } catch (e: any) {
    dispatch(updateError(e.message));
    return console.error(e);
  }
}

export const clearNotfound = () => async (dispatch: any) => {
  dispatch(updateNotFound([]));
}

export const clearBatchSearchData = () => async (dispatch: any) => {
  dispatch(updateSearchResults([]));
  dispatch(updateNotFound([]));
  dispatch(updateBatchSearchPercentage(0));
}