import React from "react";
import { useTranslation } from "react-i18next";
import './ServiceConfigurationAggregate.scss';
import { utcFormatFromString } from "../../helpers/timeFormatter";
import InfoIconWithTooltip from "../../icons/InfoIconWithTooltip";
import ServiceActivationClientResultTable from "./ServiceConfigurationMachineryResult/ServiceActivationClientResultTable";

interface ServiceConfigurationAggregateDetailPropTypes {
  Data: EquipmentAggregateDetail
}

const ServiceConfigurationAggregateDetail = ({ Data }: ServiceConfigurationAggregateDetailPropTypes) => {

  const { t } = useTranslation();

  return (
    <div className="service-activation-container">
      <div className="sdds-row sdds-u-mt2">
        <div className="sdds-col-md-6">
          <strong>{t("TS_core:ServiceActivationStatus")}: </strong>
          <span id="SA_status"><InfoIconWithTooltip uniqueId="SA_status" tooltipText={`${t('TS_core:' + Data?.machineryResult[0]?.serviceActivationStatus)}`} /></span>
          <span title={`${t("Handle: ")}${Data?.machineryResult[0].handle}`}>{Data?.machineryResult[0]?.serviceActivationStatus}</span>
        </div>
        <div className="sdds-col-md-6">
          <strong>{t("TS_core:CreatedAt")}: </strong>
          <span>{utcFormatFromString(Data?.machineryResult[0]?.timeCreated)}  </span>
        </div>
      </div>
      <div className="sdds-row sdds-u-mt2">
        <div className="sdds-col-md-12">
          <strong title={`${t("Handle: ")}${Data?.machineryResult[0].handle}`}>{t("TS_core:Purpose")}: </strong>
          <span>{Data.machineryResult[0]?.reason}</span>
        </div>
      </div>
      {/*  {
        <div id="root" className="sdds-row sdds-u-mt2">
        <div className="sdds-col-md-12">
        <table className="sdds-table sdds-table-- sdds-table--undefined" body-data={`${JSON.stringify(Data.clientResult)}`}>  
        <th className="sdds-table__header">
          <tr className="sdds-table__row">
            <th className="sdds-table__header-cell sdds-u-pr2 sdds-u-pl2" >{t("TS_core:CscCode")}</th>
            <th className="sdds-table__header-cell sdds-u-pr2 sdds-u-pl2">{t("TS_core:Description")}</th>
            <th className="sdds-table__header-cell sdds-u-pr2 sdds-u-pl2">{t("TS_core:Created")}</th>
            <th className="sdds-table__header-cell sdds-u-pr2 sdds-u-pl2">{t("TS_core:ClientStatus")}</th>
          </tr>
        </th>
        <div className="sdds-table__body">
        {Data.clientResult.map((data, index) => 
        {
          return (
            <tr className="sdds-table__row" key={index}>
              <td className="sdds-table__body-cell  sdds-u-pr2 sdds-u-pl2" title={`${t("Handle: ")}${data.handle}`}>{data.cscCode}</td>
              <td className="sdds-table__body-cell  sdds-u-pr2 sdds-u-pl2">{data.description}</td>
              <td className="sdds-table__body-cell  sdds-u-pr2 sdds-u-pl2">{utcFormatFromString(data.timeCreated)}</td>
              <td id={`c_status${index}`} className="sdds-table__body-cell  sdds-u-pr2 sdds-u-pl2">
                <InfoIcon />
                <div className="custom-tooltip">{`${t('TS_core:' + data.configurationStatus)}`}</div>
                {data.configurationStatus}</td>
            </tr>);
            
           })}
        </div>
        </table>
        </div>
        </div>
       } */}
      <div className="tds-row tds-u-mt2">
        {Data.clientResult.length > 0 && <ServiceActivationClientResultTable clientResultList={Data.clientResult} reason={Data.machineryResult[0]?.reason} />}
        {Data.clientResult.length === 0 && <div className="tds-row tds-u-mt2">{t("TS_core:NoDataFound")}</div> }
      </div>

    </div>
  )
    ;
}
export default ServiceConfigurationAggregateDetail;