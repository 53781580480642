import { AxiosPromise } from 'axios';
import { csttFacadeApi } from '@cd/sdds-common-components-react';


const fetchCscEquipment = (externalEquipmentReference: string, locale:string): AxiosPromise<CscCodeStateType> => csttFacadeApi.get(`/csttfacade/report/v3/cscequipment/${externalEquipmentReference}?locale=${locale}`);
const fetchEquipmentAggregate = (externalEquipmentReference: string): AxiosPromise<EquipmentAggregate> => csttFacadeApi.get(`/csttfacade/report/v1/csc/equipment/${externalEquipmentReference}/aggregate`);
const fetchEquipmentAggregateDetail = (externalEquipmentReference: string, locale:string): AxiosPromise<EquipmentAggregateDetail> => csttFacadeApi.get(`/csttfacade/report/v2/csc/equipment/${externalEquipmentReference}/aggregate/detail?locale=${locale}`);
const putActivateEquipment = (externalEquipmentReference: string): AxiosPromise<string> => csttFacadeApi.put(`/csttfacade/report/v1/csc/activate/${externalEquipmentReference}`);
const putOverideCscCode = (externalEquipmentReference: string, code: number, value: number): AxiosPromise<any> => csttFacadeApi.put(`/csttfacade/report/v1/csc/override/${externalEquipmentReference}/code/${code}/value/${value}`);
const fetchCscValueList = (code: number): AxiosPromise<any> => csttFacadeApi.get(`/csttfacade/report/v1/csc/configuration/cscCode/${code}`);
const deleteOverideCscCode = (externalEquipmentReference: string, code: number): AxiosPromise<any> => csttFacadeApi.delete(`/csttfacade/report/v1/csc/override/${externalEquipmentReference}/code/${code}`);
const fetchOverideCscCode = (externalEquipmentReference: string): AxiosPromise<Array<OverrideCscCodeType>> => csttFacadeApi.get(`/csttfacade/report/v1/csc/override/${externalEquipmentReference}`);
const fetchEquipmentAggregateDetailList = (externalEquipmentReference: string, locale:string): AxiosPromise<EquipmentAggregateDetailList> => csttFacadeApi.get(`/csttfacade/report/v2/csc/equipment/${externalEquipmentReference}/details?locale=${locale}`);

export {
    fetchCscEquipment,
    fetchEquipmentAggregate,
    fetchEquipmentAggregateDetail,
    putActivateEquipment,
    putOverideCscCode,
    fetchCscValueList,
    deleteOverideCscCode,
    fetchOverideCscCode,
    fetchEquipmentAggregateDetailList
};