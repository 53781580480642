
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import '../ServiceConfigurationAggregate.scss';
import { TdsFolderTab, TdsFolderTabs } from "@scania/tegel-react";
import MachineryInfoIconSection from "./MachineryInfoIconSection";
import ServiceActivationStatusSection from "./ServiceActivationStatusSection";
import ServiceActivationClientResultTable from "./ServiceActivationClientResultTable";

interface ServiceConfigurationMachineryWrapperPropTypes {
  Data?: GroupedMachineryResult[]
}

const ServiceConfigurationMachineryWrapper = ({ Data }: ServiceConfigurationMachineryWrapperPropTypes) => {

  const { t } = useTranslation();
  const tabsRef = React.createRef<HTMLTdsFolderTabsElement>();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    if (tabsRef.current) {
      tabsRef.current.addEventListener("tdsChange", (event: any) => {
        setSelectedTabIndex(event.detail.selectedTabIndex);
      });
    }
  }, [tabsRef.current]);

  return (
    <div className="service-activation-container">
      <div>
        <TdsFolderTabs ref={tabsRef}>
          <TdsFolderTab>
            <button>{t("TS_temp:Latest")}</button>
          </TdsFolderTab>
          <TdsFolderTab>
            <button>{t("TS_temp:History")}</button>
          </TdsFolderTab>
        </TdsFolderTabs>
        {selectedTabIndex === 0 && (
          <div className="tab-container latest">
            <MachineryInfoIconSection />
            {Data && Data.filter(
              (item) => (item.reason !== "History") && (item.clientResult.length !== 0)
            ).map((item, index) => (
              <div key={`${index}-${item.reason}-${item.handle}`}>
                <ServiceActivationStatusSection Data={item} UniqueId={`${index}-${item.reason}-${item.handle}`} />
              </div>
            ))}
          </div>
        )}
        {selectedTabIndex === 1 && (
          <div className="tab-container">
            <div className="sdds-col-md-12">
              {Data && Data.filter(
                (item) => item.reason === "History"
              ).map((item, index) => (
                <>
                  {item.clientResult.length > 0 ? (
                    <>
                      <MachineryInfoIconSection />
                      <div className="tds-row tds-u-mt2">
                        <ServiceActivationClientResultTable key={`${index}-${item.reason}-${item.handle}`} clientResultList={item.clientResult} reason={item.reason} />
                      </div>
                    </>
                  ) : (
                    <div key={index} className="tds-row tds-u-mt2">{t("TS_core:NoDataFound")}</div>
                  )}
                </>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default ServiceConfigurationMachineryWrapper;