import React, { memo } from "react"

interface HandlePropTypes {
  tooltipText?: string
  uniqueId?: string
  handleColor: string;
}

const HandleIcon = ({ tooltipText, uniqueId, handleColor }: HandlePropTypes) => {
  return <>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.17284 6.17393H6.18119M17.5146 11.5249L11.5308 17.5103C11.3758 17.6656 11.1917 17.7887 10.989 17.8727C10.7864 17.9568 10.5692 18 10.3499 18C10.1305 18 9.91331 17.9568 9.71068 17.8727C9.50805 17.7887 9.32396 17.6656 9.16894 17.5103L2 10.3479V2H10.3457L17.5146 9.17082C17.8255 9.48363 18 9.90679 18 10.3479C18 10.7889 17.8255 11.2121 17.5146 11.5249Z" stroke={handleColor} stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    {tooltipText && <sdds-tooltip placement="top" selector={`#${uniqueId}`} text={tooltipText}></sdds-tooltip> }
  </>
}

export default memo(HandleIcon)