import { createSlice } from '@reduxjs/toolkit'
import {
  fetchCscEquipment, fetchEquipmentAggregate, fetchEquipmentAggregateDetail, putActivateEquipment,
  putOverideCscCode, fetchCscValueList, deleteOverideCscCode, fetchOverideCscCode,
  fetchEquipmentAggregateDetailList
} from '../../adapters/ServiceConfigurationAdapter';
import { find } from 'lodash';
import _ from 'lodash';

const getInitialState = (): CscCodeStateType => {
  const State: CscCodeStateType = {
    isLoading: false,
    hasError: false,
    errorMessage: "",
    cscData: [],
    equipmentAggregate: {},
    equipmentaggregatedetail: {},
    machineryResultList: [],
    handle: "",
    isAggregateLoading: false,
    isActiveEquipmentLoading: false,
    isCscValueUpdating: false,
    updateCscCodeResponse: {},
    isCscValueLoading: false,
    stepSliderConfig: null,

    isOverideApiLoading: false,
    csc5Value: undefined,
    csc5OverrideValue: undefined,
    overrideCscData: [],
    overrideApiError: "",
    isCsc5Overriden: false,
    searchEquipmentReference:''
    
  };
  return State;
}

// Slice
const slice = createSlice({
  name: 'cscEquipment',
  initialState: getInitialState(),
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    updateCscData: (state, action) => {
      state.cscData = action.payload;
      const csc5 = find(state.cscData, { code: 5 })
      if (csc5 !== null) {
        state.csc5Value = csc5?.numValue;
      }
    },
    updateAggregateData: (state, action) => {
      state.equipmentAggregate = action.payload;
    },
    updateAggregateDetailData: (state, action) => {
      state.equipmentaggregatedetail = action.payload;
    },
    updatemachineryResultList: (state, action) => {
      state.machineryResultList = action.payload;
    },
    activateEquipmentDetails: (state, action) => {
      state.handle = action.payload;
    },
    setIsAggregateLoading: (state, action) => {
      state.isAggregateLoading = action.payload
    },
    setActiveEquipmentLoading: (state, action) => {
      state.isActiveEquipmentLoading = action.payload
    },
    setIsCscvalueUpdating: (state, action) => {
      state.isCscValueUpdating = action.payload
    },
    updateOverideCscCode: (state, action) => {
      state.updateCscCodeResponse = action.payload
    },
    getCscValueList: (state, action) => {
      state.stepSliderConfig = action.payload
    },
    setIsCscValueLoading: (state, action) => {
      state.isCscValueLoading = action.payload
    },
    updateError: (state, action) => {
      state.hasError = action.payload !== '';
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    updateIsOverideApiLoading: (state, action) => {
      state.isOverideApiLoading = action.payload
    },
    updateCsc5OverrideValue: (state, action) => {
      state.csc5OverrideValue = action.payload
    },
    updateOverrideCscData: (state, action) => {
      state.overrideCscData = action.payload
    },
    updateOverrideApiError: (state, action) => {
      state.overrideApiError = action.payload;
    },
    updateIsCsc5Overriden: (state, action) => {
      state.isCsc5Overriden = action.payload;
    },
    setSearchEquipmentReference: (state, action) => {
      state.searchEquipmentReference = action.payload
    },
  },
});
export default slice.reducer

// Actions
const { setIsLoading, updateCscData, updateAggregateData, updateAggregateDetailData, activateEquipmentDetails, setActiveEquipmentLoading, setIsAggregateLoading, updateIsCsc5Overriden,
  updateOverideCscCode, setIsCscvalueUpdating, setIsCscValueLoading, getCscValueList, updateError, updateIsOverideApiLoading, updateCsc5OverrideValue, updateOverrideCscData, setSearchEquipmentReference, updateOverrideApiError, updatemachineryResultList } = slice.actions

export const loadCscCodeData = (externalEquipmentReference: string, locale:string) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading(true));
    dispatch(setSearchEquipmentReference(externalEquipmentReference));
    let CscEquipmentResponseType = await fetchCscEquipment(externalEquipmentReference, locale);
    let cscEquipmentResponse = CscEquipmentResponseType.data.cscData;
    dispatch(updateCscData(cscEquipmentResponse));
    dispatch(setIsLoading(false));
  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

export const loadAggregateData = (externalEquipmentReference: string) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading(true));
    let equipmentAggregate = await fetchEquipmentAggregate(externalEquipmentReference);
    let equipmentAggregateResponse = equipmentAggregate.data;
    dispatch(updateAggregateData(equipmentAggregateResponse));
    dispatch(setIsLoading(false));
  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}
// TODO: Deprecate this method After integration
export const loadAggregateDetailData = (externalEquipmentReference: string, locale:string) => async (dispatch: any) => {
  try {
    dispatch(setIsAggregateLoading(true));
    let equipmentAggregateDetail = await fetchEquipmentAggregateDetail(externalEquipmentReference, locale);
    let equipmentAggregateDetailResponse = equipmentAggregateDetail.data;
    dispatch(updateAggregateDetailData(equipmentAggregateDetailResponse));
    dispatch(setIsAggregateLoading(false));
  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

export const getAllServiceActivationDetails  = (externalEquipmentReference: string, locale:string) => async (dispatch: any) => {
  try {
    dispatch(setIsAggregateLoading(true));
    let equipmentAggregateDetail = await fetchEquipmentAggregateDetailList(externalEquipmentReference, locale);
    let equipmentAggregateDetailResponse = equipmentAggregateDetail.data;
    let processList= processServiceActivationApiResponse(equipmentAggregateDetailResponse)
    dispatch(updatemachineryResultList(processList));
    dispatch(setIsAggregateLoading(false));
   } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

export const activateEquipmentData = (externalEquipmentReference: string) => async (dispatch: any) => {
  try {
    dispatch(setActiveEquipmentLoading(false));
    let activateEquipmentDetail = await putActivateEquipment(externalEquipmentReference);
    let equipmentAggregateDetailResponse = activateEquipmentDetail.data;
    dispatch(activateEquipmentDetails(equipmentAggregateDetailResponse));
    dispatch(setActiveEquipmentLoading(true));
  } catch (error: any) {
    dispatch(setActiveEquipmentLoading(false));
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

export const updateOverideCscCodeData = (externalEquipmentReference: string, code: number, value: number, locale:string) => async (dispatch: any) => {
  try {
    dispatch(setIsCscvalueUpdating(true));
    dispatch(updateError(""));
    let overideCscCodeResponseType = await putOverideCscCode(externalEquipmentReference, code, value);
    let overideCscCodeResponse = overideCscCodeResponseType.data.cscData;
    dispatch(updateOverideCscCode(overideCscCodeResponse));
    dispatch(loadCscCodeData(externalEquipmentReference, locale));
    dispatch(setIsCscvalueUpdating(false));
  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

export const getCscValueDataList = (code: number) => async (dispatch: any) => {
  try {
    dispatch(setIsCscValueLoading(true));
    let cscValues = await fetchCscValueList(code);
    let cscValueListResponse = cscValues.data;
    const values = cscValueListResponse.map((item: any) => parseInt(item.value));
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    const stepValue = Math.round((maxValue - minValue) / (values.length - 1));
    const ticks = (values.length - 2);
    const sliderConfigDetails: StepSliderConfig = {
      minValue: minValue,
      maxValue: maxValue,
      step: stepValue,
      ticks: ticks
    }
    dispatch(getCscValueList(sliderConfigDetails));
    dispatch(setIsCscValueLoading(false));
  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

export const sortCSCDataList = (sortedCscData: CscCodeResponseType[]) => async (dispatch: any) => {
  try {
    dispatch(updateCscData(sortedCscData));
  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

export const loadOverrideData = (externalEquipmentReference: string) => async (dispatch: any, getState: any) => {
  try {
    dispatch(updateIsOverideApiLoading(true));
    const state = getState().cscEquipmentStore;
    let csc5OverrideValue = undefined;
    let cscOverrideResponse = await fetchOverideCscCode(externalEquipmentReference);
    const cscCode5 = find(cscOverrideResponse.data, { code: 5 });
    if (cscCode5) {
      csc5OverrideValue = cscCode5?.numValue;
      dispatch(updateIsCsc5Overriden(state.csc5Value === csc5OverrideValue))
    }
    dispatch(updateOverrideCscData(cscOverrideResponse.data));
    dispatch(updateCsc5OverrideValue(csc5OverrideValue));
    dispatch(updateIsOverideApiLoading(false));
  } catch (error: any) {
    dispatch(updateOverrideApiError(error.message));
    return console.error(error);
  }
}

export const addCscCode5 = (externalEquipmentReference: string, code: number, value: number, locale:string) => async (dispatch: any) => {
  try {
    dispatch(updateIsOverideApiLoading(true));
    dispatch(updateOverrideApiError(""));
    await putOverideCscCode(externalEquipmentReference, code, value);
    dispatch(loadCscCodeData(externalEquipmentReference, locale));
    dispatch(loadOverrideData(externalEquipmentReference));
    dispatch(updateIsOverideApiLoading(false));
  } catch (error: any) {
    dispatch(updateOverrideApiError(error.message));
    return console.error(error);
  }
}

export const deleteOverideCscCodeData = (externalEquipmentReference: string, code: number, locale:string) => async (dispatch: any) => {
  try {
    dispatch(updateIsOverideApiLoading(true));
    await deleteOverideCscCode(externalEquipmentReference, code);
    dispatch(updateCsc5OverrideValue(undefined));
    dispatch(loadCscCodeData(externalEquipmentReference, locale));
    dispatch(loadOverrideData(externalEquipmentReference));
  } catch (error: any) {
    dispatch(updateOverrideApiError(error.message));
    return console.error(error);
  }
}

export const resetOverrideAPIError = () => async (dispatch: any) => {
  dispatch(updateOverrideApiError(""));
}

const processServiceActivationApiResponse = (apiResponse: EquipmentAggregateDetailList): GroupedMachineryResult[] => {
  
  const { machineryResult, clientResult } = apiResponse;
  const sortedMachineryResult = sortByTimeCreatedDesc(machineryResult);
  const grouped: Record<string, GroupedMachineryResult> = {};

  sortedMachineryResult.forEach((machinery) => {
    const { handle, reason, serviceActivationStatus, timeCreated } = machinery;
    if (!grouped[handle]) {
      grouped[handle] = {
        handle,
        serviceActivationStatus,
        reason,
        timeCreated,
        clientResult: []
      };
    }
    grouped[handle].clientResult = sortByTimeCreatedDesc(clientResult.filter(client => client.handle === handle));
  });

  const lowestMachineryTime = _.min(machineryResult.map((machinery) => new Date(machinery.timeCreated).getTime())) || 0;

  //#region History List
  const historyClients = clientResult.filter(client => {
    return (
      !sortedMachineryResult.some(machinery => machinery.handle === client.handle) &&
      new Date(client.timeCreated).getTime() < lowestMachineryTime
    );
  });
  if (historyClients.length >= 0) {
    grouped["History"] = {
      reason: "History",
      clientResult: sortByTimeCreatedDesc(historyClients)
    };
  }
  //#endregion

  //#region Unavailable List
  const unavailableClients = clientResult.filter(client => {
    return (
      !sortedMachineryResult.some(machinery => machinery.handle === client.handle) &&
      new Date(client.timeCreated).getTime() >= lowestMachineryTime
    );
  });
  if (unavailableClients.length >= 0) {
    grouped["Unavailable"] = {
      reason: "Unavailable",
      clientResult: sortByTimeCreatedDesc(unavailableClients)
    };
  }
  //#endregion

  return _.values(grouped);
}

const sortByTimeCreatedDesc = <T extends { timeCreated: string }>(items: T[]): T[] => {
  return _.orderBy(items, [(item) => new Date(item.timeCreated).getTime()], ["desc"]);
}