
import { TFunction } from "i18next";
import _ from "lodash";
import dayjs from 'dayjs';
import alasql from "alasql";
import utc from 'dayjs/plugin/utc';
import { utcFormatFromString } from "./timeFormatter";

dayjs.extend(utc);

const transformData = (equipments: BatchSearchRecordType[]): SimplifiedBatchSearchResultType[] => {
  var convertedData: Array<SimplifiedBatchSearchResultType> = [];
  _.forEach(equipments, function (equipment) {
    var newObject: SimplifiedBatchSearchResultType = {
      vin: equipment.vin,
      externalEquipmentReference: equipment.externalEquipmentReference,
      gatewayReferenceId: '',
      communicatorModel: '',
      communicatorVersion: '',
      mobileId: '',
      assy: '',
      imsi: '',
      msisdn: '',
      simStatus: '',
      latestCommunicationTime: equipment.latestCommunicationTime ? equipment.latestCommunicationTime : ' - ',
      latestPosition: equipment.latestPosition,
      customerName: equipment.customerName,
      customerSubscriptions: equipment.customerSubscriptions,
      latestPositionAsString: getPostionString(equipment.latestPosition),
      customerSubscriptionsAsString: equipment.customerSubscriptions.map(item => `${item.customerName}<br />${item.subscriptions.map(subscription => ` - ${subscription}<br />`).join('')}`).join('')

    };

    if (equipment.equipmentInformation !== null && equipment.equipmentInformation !== undefined) {
      _.extend(newObject, {
        gatewayReferenceId: equipment.equipmentInformation.gatewayReferenceId,
        communicatorModel: equipment.equipmentInformation.rtcType,
        communicatorVersion: equipment.equipmentInformation.rtcVersion,
        mobileId: equipment.equipmentInformation.unitId,
        assy: equipment.equipmentInformation.assy,
        simStatus: equipment.equipmentInformation.communicationStatus,
      });
    }
    if (equipment.simInformation !== null && equipment.simInformation !== undefined) {
      _.extend(newObject, {
        imsi: equipment.simInformation.imsi,
        msisdn: equipment.simInformation.msisdn,
      });
    }
    convertedData.push(newObject);
  });


  return convertedData;
}

export const filterResultsForNotfound = (requestedIds: string[], simplifiedResults: SimplifiedBatchSearchResultType[]): string[] => {
  const notfound: string[] = [];

  requestedIds.forEach((queryId: string) => {
    let isFound = false;
    for (let i = 0; i < simplifiedResults.length; i++) {
      let result: any = simplifiedResults[i];
      isFound = (_.includes(result, queryId) || result.vin.indexOf(queryId) > -1);
      if (isFound) { break; }
    }

    if (!isFound) {
      notfound.push(queryId);
    }
  });
  // const queryableFields: string[] = ["vin", "chassis", "mobileId", "imsi", "msisdn"];
  // const filterObject: Array<IHashMap[]> = requestedIds.map((queryId) => {
  //   return queryableFields.map((key) => {
  //     return { [key]: queryId }
  //   });
  // });
  // filterObject.map((id)=>{
  //   _.filter((result) => {

  //   });
  // })
  return notfound;
}

const getColumnsConfig = (t: TFunction) => {
  return {
    headers: true,
    column: {
      style: 'font-size:16px'
    },
    columns: [
      { columnid: 'vin', title: t('TS_core:Vin'), sortable: true },
      { columnid: 'externalEquipmentReference', title: t("TS_core:ExternalEquipmentReference"), sortable: true },
      { columnid: 'gatewayReferenceId', title: t("TS_core:GatewayReferenceId"), sortable: true },
      { columnid: 'communicatorModel', title: t("TS_core:CommunicatorModel"), sortable: true },
      { columnid: 'communicatorVersion', title: t("TS_core:Version"), sortable: true },
      { columnid: 'mobileId', title: t("TS_core:MobileId"), sortable: true },
      { columnid: 'assy', title: t("TS_core:Assy"), sortable: true },
      { columnid: 'imsi', title: t("TS_core:Imsi"), sortable: true },
      { columnid: 'msisdn', title: t("TS_core:Msisdn"), sortable: true },
      { columnid: 'simStatus', title: t("TS_core:SimStatus"), sortable: true },
      { columnid: 'latestCommunicationTime', title: t("TS_core:LastMessageReceived"), sortable: false },
      { columnid: 'latestPosition', title: t("TS_core:LatestPosition"), sortable: false },
      { columnid: 'customerName', title: t("TS_core:Customer"), sortable: true },
      { columnid: 'customerSubscriptions', title: t("TS_core:Subscriptions"), sortable: false }
    ]
  };
}


const getExportExcelColumnsConfig = (t: TFunction) => {
  return {
    headers: true,
    column: {
      style: 'font-size:16px'
    },
    columns: [
      { columnid: 'vin', title: t('TS_core:Vin') },
      { columnid: 'externalEquipmentReference', title: t("TS_core:ExternalEquipmentReference") },
      { columnid: 'gatewayReferenceId', title: t("TS_core:GatewayReferenceId") },
      { columnid: 'communicatorModel', title: t("TS_core:CommunicatorModel") },
      { columnid: 'communicatorVersion', title: t("TS_core:Version") },
      { columnid: 'mobileId', title: t("TS_core:MobileId") },
      { columnid: 'assy', title: t("TS_core:Assy") },
      { columnid: 'imsi', title: t("TS_core:Imsi") },
      { columnid: 'msisdn', title: t("TS_core:Msisdn") },
      { columnid: 'simStatus', title: t("TS_core:SimStatus") },
      { columnid: 'latestCommunicationTime', title: t("TS_core:LastMessageReceived") },
      { columnid: 'latestPositionAsString', title: t("TS_core:LatestPosition") },
      { columnid: 'customerName', title: t("TS_core:Customer") },
      { columnid: 'customerSubscriptionsAsString', title: t("TS_core:Subscriptions") }
    ]
  };
}

const exportBatchSearchResultsToExcel = (transformedData: Array<SimplifiedBatchSearchResultType>, t: TFunction) => {
  const config = getExportExcelColumnsConfig(t);
  const timestamp = dayjs.utc().format('YYYYMMDDHHmm')
  const filename = ["Equipment_Batch_Info", timestamp,].join('_') + '_UTC.xls';
  return alasql.promise('SELECT * INTO XLS("' + filename + '", ?) FROM ?', [config, transformedData]);
}

const getPostionString = (latestPosition: any) => {
  let returnString = ""
  if (latestPosition) {
    if (latestPosition.timePosition) {
      returnString = utcFormatFromString(latestPosition?.timePosition);
    }
    returnString += `<br /> ${latestPosition.lat} , ${latestPosition.long}`;
  }
  return returnString
}

export {
  exportBatchSearchResultsToExcel,
  transformData,
  getColumnsConfig
}