import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TdsBodyCell, TdsHeaderCell, TdsIcon, TdsTable, TdsTableBody, TdsTableBodyRow, TdsTableHeader, TdsTooltip } from "@scania/tegel-react";
import { utcFormatFromString } from "../../../helpers/timeFormatter";
import Handle from "../../assets/handle"
import "./ServiceActivationClientResultTable.scss";
import { CopySvg } from "@cd/sdds-common-components-react/lib/esm/components/assets";
import { RBACWrapper } from "react-simple-rbac";

interface ServiceActivationDetailsDataTablePropTypes {
  clientResultList: ClientResult[]
  reason?: string
}

const ServiceActivationClientResultTable = ({ clientResultList, reason }: ServiceActivationDetailsDataTablePropTypes) => {
  const { t } = useTranslation();
  const sortableTable = useRef<HTMLTdsTableElement>(null);
  const [data, setData] = useState(clientResultList);

  const handleSortEvent = (event: any) => {
    const key = event.detail.columnKey as keyof (typeof data)[0];
    const direction = event.detail.sortingDirection;
    let comparison = 0;
    const updatedData = clientResultList.slice().sort((a, b) => {
      if (a[key] < b[key]) {
        comparison = -1;
      }
      if (a[key] > b[key]) {
        comparison = 1;
      }
      return direction === 'desc' ? comparison * -1 : comparison;
    });
    setData(updatedData);
  };

  const colors = [
    "#868FA2", // Grey
    "#22682e", // Spring Green
    "#1D2229", // Black
    "#2058A8", // Dark Blue 
    "#FF8C33", // Orange
    "#6A080F", // Maroon
    "#ddc103", // Gold
    "#4A89F3", // Blue
    "#0a0185", // Dark blue
    "#006a6a"  // Teal
  ]

  const assignedColors = new Map();

  const generateColor = (handle: string) => {
    if (assignedColors.has(handle)) {
      return assignedColors.get(handle);
    }
    let hash = 0;
    for (let i = 0; i < handle.length; i++) {
      hash = handle.charCodeAt(i) + ((hash << 5) - hash);
    }
    let index = Math.abs(hash) % colors.length;
    // Ensure the color is unique
    while (Array.from(assignedColors.values()).includes(colors[index])) {
      index = (index + 1) % colors.length;
    }
    const color = colors[index];
    assignedColors.set(handle, color);
    return color;
  };

  const colorResult = (result: string) => {
    if (result === 'ConfigurationFailed')
      return "csc-failed"
  }

  return (
    <>
      <TdsTable
        tableId="service-activation-details-tabel"
        compactDesign={true}
        responsive
        ref={sortableTable}
        noMinWidth
      >
        {/* <TdsTableToolbar table-title={t("TS_temp:ServiceActivationDetails")}></TdsTableToolbar> */}
        <TdsTableHeader>
          {(reason === "History" || reason === "Unavailable") &&
            <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "FQ"]}>
              <TdsHeaderCell onTdsSort={handleSortEvent} sortable cellKey="handle" customWidth="40px">ID</TdsHeaderCell>
            </RBACWrapper>
          }
          <TdsHeaderCell onTdsSort={handleSortEvent} sortable cellKey="cscCode">{t("TS_core:CscCode")}</TdsHeaderCell>
          <TdsHeaderCell onTdsSort={handleSortEvent} sortable cellKey="description">{t("TS_core:Description")}</TdsHeaderCell>
          <TdsHeaderCell onTdsSort={handleSortEvent} sortable cellKey="timeCreated">{t("TS_temp:StatusReportedAt")}</TdsHeaderCell>
          <TdsHeaderCell onTdsSort={handleSortEvent} sortable cellKey="configurationStatus">{t("TS_temp:CSCStatus")}</TdsHeaderCell>
        </TdsTableHeader>
        {data && data.map((data, index) => {
          const uniqueId = `${index}-${data.handle}-${data.cscCode}`;
          const handleColor = generateColor(data.handle); // Function to generate color based on handle
          return (
            <TdsTableBody>
              <TdsTableBodyRow key={index}>
                {(reason === "History" || reason === "Unavailable") &&
                  <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "FQ"]}>
                    <TdsBodyCell className="sa-handle" cellKey="handle" id={`handle-${data.handle}-${uniqueId}`}>
                      <Handle handleColor={handleColor} />
                      <div className="handle-copy" onClick={() => navigator.clipboard.writeText(data.handle)} role="button" onKeyDown={() => navigator.clipboard.writeText(data.handle)}><CopySvg /></div>
                      <TdsTooltip placement="left-start" selector={`#handle-${data.handle}-${uniqueId}`} mouseOverTooltip={true} text={`Handle: ${data.handle}`} ></TdsTooltip>
                    </TdsBodyCell>
                  </RBACWrapper>
                }
                <TdsBodyCell title={`${t("Handle: ")}${data.handle}`} cellKey="cscCode">{data.cscCode}</TdsBodyCell>
                <TdsBodyCell cellKey="description">{data.description}</TdsBodyCell>
                <TdsBodyCell cellKey="timeCreated">{utcFormatFromString(data.timeCreated)}</TdsBodyCell>
                <TdsBodyCell cellKey="configurationStatus">
                  <section>
                    <TdsTooltip placement="bottom-end" selector={`#csc-status-${uniqueId}`} mouseOverTooltip={true} text={`${t('TS_core:' + data.configurationStatus)}`} ></TdsTooltip>
                    <span slot="label" id={`csc-status-${uniqueId}`} className={colorResult(data.configurationStatus)}>
                      {data.configurationStatus}
                      <TdsIcon className="tds-text-blue-500 tds-u-ml1" name="support" size="16px"></TdsIcon>
                    </span>
                  </section>
                </TdsBodyCell>
              </TdsTableBodyRow>
            </TdsTableBody>);
        })}
      </TdsTable>
    </>
  );
}

export default ServiceActivationClientResultTable;